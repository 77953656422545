<template>
  <div class="waylay">
    <el-card class="my-card">
      <div slot="header">
        <span style="font-size: 20px;font-weight: bolder;color:#51cf66">通知领货</span>
      </div>
      <div class="Logistics-warning">
        <div>
          <el-table
            :data="tableData1"
            border
            v-loading="isLoading"
            style="width: 100%;font-size: 20px;font-weight: bolder"
            size="medium"
            :row-style="{height:'40px'}"
            :cell-style="{padding:'2px'}">
            <el-table-column type="index" label="编号" width="80"></el-table-column>
            <el-table-column prop="productNo" label="生产编码" ></el-table-column>
<!--            <el-table-column prop="productType" label="生产类型">-->
<!--              <template slot-scope="scope">-->
<!--                <span>{{ scope.row.productType | productTypeFormate }}</span>-->
<!--              </template>-->
<!--            </el-table-column>-->
            <el-table-column prop="product" label="产品名称"></el-table-column>
            <el-table-column prop="weight" label="重量:KG"></el-table-column>
            <el-table-column prop="need" label="需要楼层">
              <template slot-scope="scope">
                <span>{{ scope.row.need | needStatusFormate}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="mark" label="备注"></el-table-column>
<!--            <el-table-column prop="productStatus" label="生产状态">-->
<!--              <template slot-scope="scope">-->
<!--                <span>{{ scope.row.productStatus | productStatusFormate }}</span>-->
<!--              </template>-->
<!--            </el-table-column>-->
            <el-table-column prop="outTime" label="通知出库时间">
              <template slot-scope="scope">
                <span>{{ scope.row.outTime | dateFormate }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="{row}">
                <el-button @click="sign(row)" type="success" size="mini" v-if="'31'===row.productStatus">确认送达</el-button>
              </template>
            </el-table-column>

          </el-table>
        </div>
        <!--      分页组件结构-->
        <el-pagination
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="form1.currentPage"
          :page-sizes="[10,50,100]"
          :page-size="form1.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dataTotal1"
          :disabled="isLoading"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-card class="my-card">
      <div slot="header">
        <span style="font-size: 20px;font-weight: bolder;color:#FFA500">等待通知</span>
      </div>
      <div class="Logistics-warning">
        <div>
          <el-table
            :data="tableData2"
            border
            v-loading="isLoading"
            style="width: 100%;font-size: 20px;font-weight: bolder"
            :row-style="{height:'40px'}"
            :cell-style="{padding:'2px'}">
            <el-table-column type="index" label="编号" width="80"></el-table-column>
            <el-table-column prop="productNo" label="生产编码" ></el-table-column>
            <el-table-column prop="productType" label="生产类型">
              <template slot-scope="scope">
                <span>{{ scope.row.productType | productTypeFormate }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="product" label="产品名称"></el-table-column>
            <el-table-column prop="weight" label="重量:KG"></el-table-column>
            <el-table-column prop="need" label="需要楼层">
              <template slot-scope="scope">
                <span>{{ scope.row.need | needStatusFormate}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="mark" label="备注"></el-table-column>
            <el-table-column prop="productStatus" label="生产状态">
              <template slot-scope="scope">
                <span>{{ scope.row.productStatus | productStatusFormate }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="releasTime" label="发布时间">
              <template slot-scope="scope">
                <span>{{ scope.row.releasTime | dateFormate }}</span>
              </template>
            </el-table-column>

          </el-table>
        </div>
        <!--      分页组件结构-->
        <el-pagination
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="form2.currentPage"
          :page-sizes="[10,50,100]"
          :page-size="form2.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dataTotal2"
          :disabled="isLoading"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-card class="my-card">
      <div slot="header">
        <span style="font-size: 20px;font-weight: bolder;color:#51cf66">状态查询</span>

      </div>
      <el-form :inline="true" ref="form4" :model="form4" class="demo-form-inline" size="small">
        <el-form-item label="生产状态：" prop="productStatus">
          <el-select v-model="form4.productStatus" placeholder="全部" clearable>
            <el-option v-for="item in productStatusList" :key="item.status" :label="item.statusDesc"
                       :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="需要楼层：" prop="need">
          <el-select v-model="form4.need" clearable>
            <el-option v-for="item in needStatusList" :key="item.status" :label="item.statusDesc"
                       :value="item.status"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="确认送达时间：">
          <el-date-picker
            v-model="form4.dateQuery4"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format='yyyy-MM-dd HH:mm:ss' value-format='yyyy-MM-dd HH:mm:ss' >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="mini" :disabled="isLoading">查询</el-button>
        </el-form-item>

      </el-form>
      <div class="Logistics-warning">
        <div>
          <el-table
            :data="tableData4"
            border
            v-loading="isLoading"
            style="width: 100%;font-size: 20px;font-weight: bolder"
            size="medium"
            :row-style="{height:'40px'}"
            :cell-style="{padding:'2px'}">
            <el-table-column type="index" label="编号" width="80"></el-table-column>
            <el-table-column prop="productNo" label="生产编码" ></el-table-column>
            <el-table-column prop="product" label="产品名称"></el-table-column>
            <el-table-column prop="weight" label="重量:KG"></el-table-column>
            <el-table-column prop="need" label="需要楼层">
              <template slot-scope="scope">
                <span>{{ scope.row.need | needStatusFormate}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="mark" label="备注"></el-table-column>
            <el-table-column prop="productStatus" label="生产状态">
              <template slot-scope="scope">
                <span>{{ scope.row.productStatus | productStatusFormate }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="signTime" label="确认送达时间">
              <template slot-scope="scope">
                <span>{{ scope.row.signTime | dateFormate }}</span>
              </template>
            </el-table-column>

          </el-table>
        </div>
        <!--      分页组件结构-->
        <el-pagination
          @size-change="handleSizeChange4"
          @current-change="handleCurrentChange4"
          :current-page="form4.currentPage"
          :page-sizes="[10,50,100]"
          :page-size="form4.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dataTotal4"
          :disabled="isLoading"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import {dateFormate} from "@/utils/dateUtil";
import {getUserInfo} from '@/services/user'
import {findReleasProductInfoByPage,sign,findAllProductInfoByPage} from "@/services/produce";
import {productStatusToCn,productTypeToCn,needStatusToCn} from '@/utils/enums'
import Speech from 'speak-tts'
import {findAllProductStatus,findAllNeedStatus} from '@/services/status'
export default {
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    this.speechInit();
    this.timer = setInterval(() => {
      this.loadUserInfo()
      this.loadProductStatus()
      this.loadNeedStatus()
      this.loadReleasProductInfoByPage1()
      this.loadReleasProductInfoByPage2()
      this.loadReleasProductInfoByPage4()
    }, 60*1000*10);

  },
  name: 'notice',
  data() {
    return {
      productStatusList: [],
      needStatusList: [],
      timer:'',
      userInfo:{},
      tableData1: [],
      form1: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 10,
        productStatus: '31'
      },
      dataTotal1: 0,

      tableData2: [],
      form2: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 10,
        productStatus: '19,23,27'
      },
      dataTotal2: 0,

      tableData4: [],
      form4: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 10,
        productStatus: '35'
      },
      dataTotal4: 0,
      isLoading: false,
    }
  },
  methods: {
    // 添加筛选后的数据查询
    async onSubmit() {
      // 请求数据前，将请求的页数更新为1
      this.form4.currentPage = 1
      await this.loadReleasProductInfoByPage4()
    },
    async loadProductStatus() {
      const {data} = await findAllProductStatus()
      if (200 === data.code) {
        this.productStatusList = data.data
      } else {
        this.$message.error('查询状态列表失败')
      }
    },
    async loadNeedStatus() {
      const {data} = await findAllNeedStatus()
      if (200 === data.code) {
        this.needStatusList = data.data
      } else {
        this.$message.error('查询需方列表失败')
      }
    },
    async loadUserInfo() {
      const {data} = await getUserInfo()
      if (data.code === 200) {
        return this.userInfo = data.data
      }
      this.$message.error('获取用户信息失败')
    },
    async sign(row) {
      const params = {
        productNo: row.productNo,
        tackName: this.userInfo.userName,
        tackPhone: this.userInfo.phone
      }
      const {data} = await sign(params)
      if (200 === data.code) {
        this.$message.success("操作成功")
      } else {
        this.$message.warning("操作失败")
      }
      await this.loadReleasProductInfoByPage1()
    },
    // 每页显示条数变化时触发
    handleSizeChange1(val) {
      this.form1.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.form1.currentPage = 1
      this.loadReleasProductInfoByPage1()
    },
    // 每页显示条数变化时触发
    handleSizeChange2(val) {
      this.form2.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.form2.currentPage = 1
      this.loadReleasProductInfoByPage2()
    },
// 每页显示条数变化时触发
    handleSizeChange4(val) {
      this.form4.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.form4.currentPage = 1
      this.loadReleasProductInfoByPage4()
    },

    // 页号改变时触发
    handleCurrentChange1(val) {
      this.form1.currentPage = val
      this.loadReleasProductInfoByPage1()
    },
    // 页号改变时触发
    handleCurrentChange2(val) {
      this.form2.currentPage = val
      this.loadReleasProductInfoByPage2()
    },
    // 页号改变时触发
    handleCurrentChange4(val) {
      this.form4.currentPage = val
      this.loadReleasProductInfoByPage4()
    },
    async loadReleasProductInfoByPage1() {
      // 开始加载数据 生产模块
      this.isLoading = true
      if (!this.userInfo||!this.userInfo.userName) {
        await this.loadUserInfo()
      }
      this.form1.tackName=this.userInfo.userName
      this.form1.tackPhone=this.userInfo.phone
      const {data} = await findReleasProductInfoByPage(this.form1)
      if (200 === data.code) {
        this.tableData1 = data.data.records
        this.dataTotal1 = data.data.total
      }
      if (!this.tableData1==false&&this.tableData1.length>0) {
        const {product} = this.tableData1[0]
        await this.open(product)
      }
      this.isLoading = false
    },
    async loadReleasProductInfoByPage2() {
      // 开始加载数据 生产模块
      this.isLoading = true
      const {data} = await findReleasProductInfoByPage(this.form2)
      if (200 === data.code) {
        this.tableData2 = data.data.records
        this.dataTotal2 = data.data.total
      }
      this.isLoading = false
    },
    async loadReleasProductInfoByPage4() {
      this.isLoading = true
      if (this.form4 && this.form4.dateQuery4) {
        let signDateBegin = this.form4.dateQuery4[0]
        let signDateEnd = this.form4.dateQuery4[1]
        this.form4.signDateBegin = signDateBegin
        this.form4.signDateEnd = signDateEnd
      }else {
        this.form4.signDateBegin = ""
        this.form4.signDateEnd = ""
      }
      const {data} = await findAllProductInfoByPage(this.form4)
      if (200 === data.code) {
        this.tableData4 = data.data.records
        this.dataTotal4 = data.data.total
      }
      this.isLoading = false
    },
    async open(msg) {
      await this.$notify({
        title: '提示',
        message: msg
      });
      msg = "有"+msg + "领取";
      let date = new Date();
      let minutes = date.getMinutes(); // 分
      let i = minutes%5;
      if (i==1) {
        await this.speak(msg)
      }

      // await this.speechCancel()
    },

    //语音播报
    async speechCancel() {
      await this.speech.cancel()
      console.log('语音播报退出...')
    },
    //语音播报
    speechInit(){
      this.speech = new Speech();
      this.speech.setLanguage('zh-CN');
      this.speech.init().then(()=>{
        console.log('语音播报初始化完成...')
      })
    },
    //语音播报
    speak(msg){
      this.speech.speak({text:msg}).then(()=>{
        console.log("播报完成...")
      })
    },
  },
  created() {
    this.speechInit();
    this.loadUserInfo()
    this.loadProductStatus()
    this.loadNeedStatus()
    this.loadReleasProductInfoByPage1()
    this.loadReleasProductInfoByPage2()
    this.loadReleasProductInfoByPage4()
  },
  filters: {
    // 日期过滤器
    dateFormate(date) {
      return dateFormate(date)
    },
    productStatusFormate(code) {
      return productStatusToCn(code)
    },
    productTypeFormate(code) {
      return productTypeToCn(code)
    },

    needStatusFormate(code) {
      return needStatusToCn(code)
    },

    // topStatusFormate(code) {
    //   return topStatusToCn(code)
    // },


  }
}
</script>

<style lang="scss" scoped>
.my-card {
  margin-bottom: 10px;
}
</style>
