import request from '@/utils/request'

// 分页多条件生产排班查询
export function findAllProductInfoByPage(data) {
  return request({
    method: 'post',
    url: `/auth/productInfo/findAllProductInfoByPage`,
    data
  })
}

// 查询所有产品名称
export function findAllProductList() {
  return request({
    method: 'post',
    url: `/auth/productInfo/findAllProductList`,
  })
}

// 已发布生产信息分页&多条件查询
export function findReleasProductInfoByPage(data) {
  return request({
    method: 'post',
    url: `/auth/productInfo/findReleasProductInfoByPage`,
    data
  })
}


// 添加生产排班信息
export function saveProductInfo(data) {
  return request({
    method: 'post',
    url: `/auth/productInfo/saveProductInfo`,
    data
  })
}

// 生产跟进记录列表
export function productTackList(data) {
  return request({
    method: 'post',
    url: `/auth/productInfo/tackList`,
    data
  })
}
// 生产申请
export function apply(data) {
  return request({
    method: 'post',
    url: `/auth/productInfo/apply`,
    data
  })
}

// 生产排班发布
export function releas(data) {
  return request({
    method: 'post',
    url: `/auth/productInfo/releas`,
    data
  })
}

// 正在生产
export function prod(data) {
  return request({
    method: 'post',
    url: `/auth/productInfo/prod`,
    data
  })
}

// 完成生产
export function finish(data) {
  return request({
    method: 'post',
    url: `/auth/productInfo/finish`,
    data
  })
}

// 通知出库
export function out(data) {
  return request({
    method: 'post',
    url: `/auth/productInfo/out`,
    data
  })
}

// 确认送达楼层
export function sign(data) {
  return request({
    method: 'post',
    url: `/auth/productInfo/sign`,
    data
  })
}

// 删除数据
export function del(data) {
  return request({
    method: 'post',
    url: `/auth/productInfo/del`,
    data
  })
}

// 导出快递数据
export function downProductInfo(data) {
  return request({
    method: 'post',
    url: `/auth/productInfo/exportData`,
    data
  })
}
